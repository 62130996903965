import { Trans, useLingui } from '@lingui/react/macro';
import { GQGetJobResultsQuery } from '@watershed/shared-frontend/generated/graphql-operations';

import { SnackbarKey } from 'notistack';
import useSnackbar from '@watershed/shared-frontend/hooks/useSnackbar';
import { Box, Stack, Typography } from '@mui/material';
import CircularProgress from '@watershed/ui-core/components/CircularProgress';
import Button from '@watershed/ui-core/components/Button';
import { OperationResult, useClient, Client } from 'urql';
import { useCallback } from 'react';
import invariant from 'invariant';
import {
  pollForJobResults,
  parseBackgroundJobUrqlResult,
} from './pollForBackgroundJob';
import {
  backgroundJobUrl,
  temporalWorkflowUrl,
} from '@watershed/shared-universal/adminRoutes';
import PopOutIcon from '@watershed/icons/components/PopOut';
import { useCancelWorkflowMutation } from '../generated/urql';
import { maybeNotifySentry } from './errorUtils';
import {
  BackgroundJobErrorReason,
  BackgroundJobError,
} from '@watershed/shared-universal/utils/backgroundJobUtils';
import {
  ISerializableError,
  makeSerializableError,
} from '@watershed/shared-universal/utils/serializableError';
import DetailedError from '../components/DetailedError';
import Link from '@watershed/ui-core/components/TextLink';
import CloseIcon from '@watershed/icons/components/Close';

export async function waitForBackgroundJobResult(
  gqlClient: Client,
  jobId: string,
  getShouldCancelPolling: () => boolean = () => false
) {
  const output: { result?: any; error?: Error } = {};

  let pollResponse: OperationResult<GQGetJobResultsQuery, any>;
  try {
    pollResponse = await pollForJobResults(
      gqlClient,
      jobId,
      getShouldCancelPolling
    );
  } catch (pollingError) {
    output.error = pollingError as Error;
    return output;
  }

  if (!pollResponse) {
    return output;
  }

  return parseBackgroundJobUrqlResult(pollResponse);
}

// These BackgroundJobErrorReasons are rendered as warnings, as opposed to
// true errors.
const WARNING_REASONS = new Set([
  BackgroundJobErrorReason.CANCELED,
  BackgroundJobErrorReason.RESULT_EMPTY,
  BackgroundJobErrorReason.RESULT_ERROR,
  BackgroundJobErrorReason.RESULT_UNSUCCESSFUL,
]);

export interface BackgroundJobSnackbarInput {
  jobId: string;
  jobDescription: string;
  customSuccessAction?: JSX.Element;
  customErrorAction?: JSX.Element;
  onSuccess?: (result: any) => void;
  onError?: (error: BackgroundJobError | Error) => void;
  onDone?: () => void;
  persistOnSuccess?: boolean;
}

export interface LaunchBackgroundJobSnackbar {
  (input: BackgroundJobSnackbarInput): Promise<void>;
}

/**
 * Simple snackbar content for better consistency here
 */
const JobDataSnackbar = ({
  jobDescription,
  jobId,
  isRunning,
  isCanceling,
  error,
}: Pick<BackgroundJobSnackbarInput, 'jobId' | 'jobDescription'> &
  (
    | {
        isRunning?: boolean;
        isCanceling?: never;
        error?: never;
      }
    | {
        isRunning?: never;
        isCanceling?: never;
        error: {
          serializableError: ISerializableError | null;
          message: string;
          errorPhrase: string;
        };
      }
    | {
        isRunning?: never;
        isCanceling: true;
        error?: never;
      }
  )) => {
  const content = (
    <Stack gap={0.5}>
      <Box>
        <Box
          component="span"
          sx={{
            fontWeight: 700,
            wordBreak: 'break-all',
            lineClamp: 2,
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            display: '-webkit-box',
            overflow: 'hidden',
          }}
        >
          {isCanceling ? (
            <Trans context="Background job here means a task we have been running in the background">
              Canceling background job {jobDescription}
            </Trans>
          ) : isRunning ? (
            <Trans context="Background job here means a task we have been running in the background">
              Running background job {jobDescription}
            </Trans>
          ) : error ? (
            error.errorPhrase
          ) : (
            <Trans context="Background job here means a task we have been running in the background">
              Finished background job {jobDescription}
            </Trans>
          )}
        </Box>
      </Box>
      <Typography variant="caption">
        <Link
          href={temporalWorkflowUrl(jobId)}
          sx={{
            color: (theme) => theme.palette.grey70,
            textDecoration: 'underline',
          }}
        >
          {jobId}
        </Link>
      </Typography>
      {error?.serializableError && (
        <Typography
          variant="caption"
          sx={{
            mt: 1,
            pl: 1.5,
            borderLeft: (theme) => `4px solid ${theme.palette.grey100}`,
          }}
        >
          <DetailedError
            error={error.serializableError}
            detailsButtonPosition="bottom"
          />
        </Typography>
      )}
    </Stack>
  );

  return isRunning || isCanceling ? (
    <Stack direction="row" alignItems="center" gap={2}>
      {content}
      <Stack justifyContent="center">
        <CircularProgress size={16} color="inherit" />
      </Stack>
    </Stack>
  ) : (
    content
  );
};

/**
 * A hook that provides a function for launching a background job snackbar. Its
 * watches a background job; shows a spinner while it's going; then shows a
 * success or error state when it finishes.
 *
 * TODO(DI-3989): this is the Admin version which was moved into
 * `shared-frontend` when reusing some higher-level components. We had
 * previously forked out a dashboard version, but should just reconsolidate into
 * one as we continue this "to product" push.
 */
export function useBackgroundJobSnackbar(): {
  launchBackgroundJobSnackbar: LaunchBackgroundJobSnackbar;
} {
  const snackbar = useSnackbar();
  const { t } = useLingui();
  const gqlClient = useClient();
  const [, executeCancelWorkflow] = useCancelWorkflowMutation();

  const launchBackgroundJobSnackbar = useCallback(
    async ({
      jobId,
      jobDescription,
      customSuccessAction,
      customErrorAction,
      onSuccess,
      onError,
      onDone,
      persistOnSuccess = true,
    }: BackgroundJobSnackbarInput) => {
      let isCancelingSnackbarKey: SnackbarKey | null = null;
      const cancelJob = async (snackbarKey: SnackbarKey) => {
        snackbar.closeSnackbar(snackbarKey);
        isCancelingSnackbarKey = snackbar.enqueueSnackbar(
          <JobDataSnackbar
            jobDescription={jobDescription}
            jobId={jobId}
            isCanceling
          />,
          /* This uses the running key so that if you queue another "running" notification while
           * waiting to cancel that job, it'll disappear. Doesn't make sense to be able to queue
           * running while there's something canceling.
           */
          { key: `${jobId}running`, persist: true, preventDuplicate: true }
        );
        try {
          const res = await executeCancelWorkflow({
            input: { workflowId: jobId },
          });
          if (res.error) {
            throw res.error;
          }
        } catch (err: any) {
          snackbar.enqueueSnackbar(
            t({
              message: `Error canceling job: ${err.toString()}`,
              context: 'Error message',
            }),
            {
              key: `${jobId}errCanceling`,
              variant: 'error',
              preventDuplicate: true,
            }
          );
        }
      };
      const snackbarKey = snackbar.enqueueSnackbar(
        <JobDataSnackbar
          jobDescription={jobDescription}
          jobId={jobId}
          isRunning
        />,
        {
          key: `${jobId}running`,
          persist: true,
          preventDuplicate: true,
          action: (
            <Button onClick={() => cancelJob(snackbarKey)}>
              <Trans context="button copy">Cancel</Trans>
            </Button>
          ),
        }
      );

      const resultButton = (
        <Button
          href={backgroundJobUrl(jobId)}
          startIcon={<PopOutIcon size={14} />}
        >
          <Trans context="button copy">Result</Trans>
        </Button>
      );

      const { result, error } = await waitForBackgroundJobResult(
        gqlClient,
        jobId
      );
      invariant(
        result || error,
        'either result or error should be non-nullish'
      );

      if (error) {
        let snackbarVariant: 'error' | 'warning' | 'info';
        let errorPhrase = 'Failed';
        let message = `Failed background job "${jobDescription}":\n${
          (error as Error).message
        }`;
        let serializableError: ISerializableError | null =
          makeSerializableError(error);

        if ((error as any).code === 'NETWORK') {
          console.warn(`Failed polling for background job ${jobId}`, error);
          errorPhrase = `Polling for status on`;
          message = `Polling for background job ${jobId} failed. Click Result to follow up.`;
          snackbarVariant = 'warning';
          maybeNotifySentry(error);
        }
        if ((error as any).reason === BackgroundJobErrorReason.CANCELED) {
          errorPhrase = 'User canceled';
          message = `Background job ${jobDescription} was canceled.`;
          serializableError = null;
          snackbarVariant = 'info';
        } else if (WARNING_REASONS.has((error as any).reason)) {
          errorPhrase = 'Finished with warnings';
          console.warn(`Failed background job ${jobId}`, error);
          snackbarVariant = 'warning';
        } else {
          console.error(`Failed background job ${jobId}`, error);
          snackbarVariant = 'error';
        }
        const failureKey = snackbar.enqueueSnackbar(
          <JobDataSnackbar
            jobDescription={jobDescription}
            jobId={jobId}
            error={{
              serializableError,
              errorPhrase,
              message,
            }}
          />,
          {
            key: `${jobId}failed`,
            persist: true,
            variant: snackbarVariant,
            preventDuplicate: true,
            action: (
              <Stack direction="row" gap={1}>
                {customErrorAction}
                {resultButton}
                <Button
                  onClick={() => snackbar.closeSnackbar(failureKey)}
                  isIcon
                  startIcon={<CloseIcon size={14} />}
                />
              </Stack>
            ),
          }
        );
        if (onError) {
          onError(error as Error);
        }
      } else {
        const successKey = snackbar.enqueueSnackbar(
          <JobDataSnackbar jobDescription={jobDescription} jobId={jobId} />,
          {
            key: `${jobId}success`,
            persist: persistOnSuccess,
            variant: 'success',
            preventDuplicate: true,
            action: (
              <Stack direction="row" gap={1}>
                {customSuccessAction}
                {resultButton}
                <Button
                  onClick={() => snackbar.closeSnackbar(successKey)}
                  isIcon
                  startIcon={<CloseIcon size={14} />}
                />
              </Stack>
            ),
          }
        );
        if (onSuccess) {
          onSuccess(result);
        }
      }

      if (onDone) {
        onDone();
      }

      // We can't replace the content of a snackbar, so we close the one for the
      // running state after opening a new one for the end state, & same for the canceling one
      snackbar.closeSnackbar(snackbarKey);
      if (isCancelingSnackbarKey) {
        snackbar.closeSnackbar(isCancelingSnackbarKey);
      }
    },
    [snackbar, gqlClient, executeCancelWorkflow, t]
  );

  return { launchBackgroundJobSnackbar };
}
