/* eslint-disable @watershed/literals-must-be-i18n-ready */
import gql from 'graphql-tag';
import { GQCreateDemoOrgInput } from '@watershed/shared-universal/generated/graphql-schema-types';
import { useCreateDemoOrgMutation } from '@watershed/shared-frontend/generated/urql-common';

import { useState } from 'react';
import { Field, Formik } from 'formik';
import DialogForm from '@watershed/ui-core/components/DialogForm';
import ErrorBox from '@watershed/ui-core/components/ErrorBox';
import must from '@watershed/shared-universal/utils/must';
import TextField from '@watershed/ui-core/components/Form/TextField';
import { Checkbox, Typography } from '@mui/material';
import { useBackgroundJobSnackbar } from '../../utils/useBackgroundJobSnackbar';
import CreateOrganizationDataResidencyRegionField from '../../components/CreateOrganizationDataResidencyRegionField';
import { getRegionFromFrontend } from '../../utils/dataResidency';
import TextLink from '@watershed/ui-core/components/TextLink';
import Callout from '../Callout';
import { routeForDemoPlatformNew } from '@watershed/shared-universal/dashboardRoutes';
import { dashboardUrl } from '@watershed/shared-universal/utils/helpers';

gql`
  mutation CreateDemoOrg($input: CreateDemoOrgInput!)
    @withOwner(owner: EnterpriseFoundations) {
    createDemoOrg(input: $input) {
      backgroundJobId
    }
  }
`;

export default function CreateDemoOrgDialog({
  onSuccess,
  onSubmitComplete,
  onClose,
  userId,
}: {
  onSuccess?: ({ orgId }: { orgId: string }) => void;
  onSubmitComplete?: () => void;
  onClose: () => void;
  userId: string;
}) {
  const [error, setError] = useState<string | null>(null);
  const [{ fetching }, executeCreateDemoOrg] = useCreateDemoOrgMutation();
  const { launchBackgroundJobSnackbar } = useBackgroundJobSnackbar();
  const region = getRegionFromFrontend();

  return (
    <Formik<GQCreateDemoOrgInput>
      initialValues={{
        name: '',
        userName: '',
        testOrg: false,
        stagingOrg: false,
        // grant OrgAccess & Admin permission for the Watershed employee creating the demo org
        grantAccessUserIds: [userId],
        companySourceKey: 'WatershedEmployee',
      }}
      onSubmit={async (input) => {
        const { data, error } = await executeCreateDemoOrg({ input });

        if (error) {
          setError(error.message);
          return false;
        } else {
          onSubmitComplete?.();
          onClose();
          await launchBackgroundJobSnackbar({
            jobId: must(data?.createDemoOrg?.backgroundJobId),
            jobDescription: 'Create demo organization',
            onSuccess: async (result: any) => {
              // Set timeout to not immediately smoke the success message
              setTimeout(() => {
                onSuccess?.({ orgId: result.org_id });
              }, 1000);
            },
          });
          return true;
        }
      }}
    >
      {(form) => (
        <DialogForm
          onClose={onClose}
          isSubmitting={fetching}
          header={{ title: 'Create demo organization' }}
          submit="Create"
        >
          <TextField
            label="Organization name"
            id="name"
            placeholder="Apple Computer"
            required
          />
          <TextField
            label="Admin user name"
            id="userName"
            placeholder="Johnny Appleseed"
            required
          />

          <CreateOrganizationDataResidencyRegionField
            id="region"
            name="region"
            region={region}
          />

          <fieldset>
            <Typography variant="h5" component="legend">
              Is this a Test org?
            </Typography>
            <Typography variant="body2">
              Test orgs are used for testing and developing new features. Errors
              on test orgs are expected and will not alert engineering oncalls.
              Please do NOT use test orgs to demo to customers and prospects.
            </Typography>
            <Field as={Checkbox} row id="testOrg" name="testOrg" />
          </fieldset>
          {error && <ErrorBox>{error}</ErrorBox>}
          <Callout
            variant="warning"
            title="You will need to seed data with Demo Platform after this!"
            description={
              <Typography>
                Navigate to{' '}
                <TextLink href={dashboardUrl(routeForDemoPlatformNew())}>
                  {dashboardUrl(routeForDemoPlatformNew())}
                </TextLink>{' '}
                after creating the org and clicking "Login as...".
              </Typography>
            }
          />
        </DialogForm>
      )}
    </Formik>
  );
}
