import { Box, Popover, SxProps, Theme } from '@mui/material';
import ChevronDown from '@watershed/icons/components/ChevronDown';
import {
  memo,
  ReactNode,
  useState,
  useRef,
  useId,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from 'react';
import {
  mixinSx,
  popoverTransitionDuration,
} from '@watershed/style/styleUtils';
import Button, { ButtonProps } from './Button';
import { focusable } from 'tabbable';
type PopoverButtonAnalyticsEvent = 'open' | 'close';

export interface PopoverButtonHandle {
  open: () => void;
  close: () => void;
}

/**
 * A lower-level component for creating a popover. If you’re looking for a menu,
 * try `PopoverMenu` or `DropdownMenu`, for a radio selection `RadioSelect`,
 * for a checkbox selection `CheckboxSelectNonFormik`. This component uses tabbable library
 * to focus the first focusable element in the popover when it opens for improved
 * accessibility. This is important for keyboard navigation, as it allows users to
 * interact with the popover content without having to manually tab through other
 * elements. It enhances the user experience for those using keyboard navigation
 * or assistive technologies, ensuring a more efficient interaction flow.
 */

export type PopoverButtonProps = Pick<
  ButtonProps,
  | 'disabled'
  | 'startIcon'
  | 'endIcon'
  | 'tooltip'
  | 'variant'
  | 'size'
  | 'color'
> & {
  children: ReactNode | ((closePopover: () => void) => ReactNode);
  buttonLabel?: ReactNode;
  sx?: SxProps<Theme>;
  paperSx?: SxProps<Theme>;
  flat?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  onClose?: () => void;
  onAnalyticsEvent?: (event: PopoverButtonAnalyticsEvent) => void;
  /**
   * By default, the popover takes up the full height of the screen minus some padding
   * (16px). If you want to instead make sure the popover appears below its popover button,
   * set this to true.
   */
  keepButtonVisible?: boolean;
  alignRight?: boolean;
  testId?: string;
  defaultOpen?: boolean;
  maxHeight?: number;
  /** Enable experiment use of native popover elements */
  exp_useNativePopover?: boolean;
};

export default memo(
  forwardRef<PopoverButtonHandle, PopoverButtonProps>(function PopoverButton(
    {
      children,
      buttonLabel,
      sx,
      paperSx,
      startIcon,
      disabled,
      flat,
      tooltip,
      color,
      variant,
      size,
      onClick,
      onClose,
      onAnalyticsEvent = () => {},
      endIcon = <ChevronDown />,
      keepButtonVisible,
      alignRight,
      testId,
      defaultOpen,
      maxHeight,
      exp_useNativePopover = false,
    },
    ref
  ) {
    const popoverAnchorRef = useRef<HTMLButtonElement | null>(null);
    const [open, setOpen] = useState(defaultOpen ?? false);
    const popoverContentRef = useRef<HTMLDivElement | null>(null);
    const popoverId = useId();

    // Uses tabbable's focusable to focus the first focusable element in the popover when it opens
    // This is important for accessibility and user experience:
    // 1. It ensures keyboard users can immediately interact with the popover content without having to manually tab to it
    // 2. It provides a clear visual indication that the popover has opened and is now the active element
    // 3. It maintains a logical tab order, preventing users from getting lost in the UI
    // 4. It complies with WCAG 2.1 guideline 2.4.3 (Focus Order) by managing focus when new content appears
    const focusFirstFocusableElement = useCallback(() => {
      requestAnimationFrame(() => {
        if (popoverContentRef.current) {
          const tabbableElements = focusable(popoverContentRef.current);
          if (tabbableElements.length) {
            tabbableElements[0].focus();
          }
        }
      });
    }, []);

    const onClosePopover = useCallback(() => {
      setOpen(false);
      if (typeof onClose === 'function') {
        onClose();
      }
    }, [onClose]);

    const handleOpen = useCallback(() => {
      onAnalyticsEvent('open');
      setOpen(true);
      focusFirstFocusableElement();
    }, [onAnalyticsEvent, focusFirstFocusableElement]);

    const handleClose = useCallback(() => {
      onAnalyticsEvent('close');
      onClosePopover();
    }, [onAnalyticsEvent, onClosePopover]);

    const handleClick = useCallback(
      (e: React.MouseEvent) => {
        handleOpen();
        onClick?.(e);
      },
      [handleOpen, onClick]
    );

    // Allow parent components to imperatively open and close the popover.
    useImperativeHandle(ref, () => ({
      open: handleOpen,
      close: handleClose,
    }));

    /**
     * Sometimes, we want to make sure our popover doesn't cover the anchor button that opens it.
     * This function returns the min Y value that the popover can be anchored to.
     */
    function getMinYForPopover() {
      return (
        (popoverAnchorRef.current?.getBoundingClientRect().bottom ?? 0) +
        16 /* to match the default window padding set by the popover library already */ +
        2 /* for border */
      );
    }

    const horizontalAlignment = alignRight ? 'right' : 'left';

    return (
      <>
        <Button
          data-testid={testId}
          ref={popoverAnchorRef}
          startIcon={startIcon}
          disabled={disabled}
          flat={flat}
          endIcon={endIcon}
          color={color}
          variant={variant}
          size={size}
          tooltip={tooltip}
          // Fix for a11y issue where aria-label is passed to wrapping div with tooltip
          aria-label={typeof tooltip === 'string' ? tooltip : undefined}
          onClick={handleClick}
          className="UIPopoverButton"
          sx={mixinSx({ whiteSpace: 'nowrap' }, sx)}
          aria-haspopup
          // @ts-expect-error
          popovertarget={exp_useNativePopover ? popoverId : undefined}
        >
          {buttonLabel && (
            <Box
              data-testid={`${testId}-label`}
              component="span"
              sx={{
                flexGrow: 1,
                textAlign: 'left',
                textOverflow: 'ellipsis',
                width: 'fill-available',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                lineHeight: 1.6,
              }}
            >
              {buttonLabel}
            </Box>
          )}
        </Button>
        <Popover
          disablePortal={exp_useNativePopover}
          anchorEl={popoverAnchorRef.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: horizontalAlignment,
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: horizontalAlignment,
          }}
          open={open}
          onClose={handleClose}
          transitionDuration={popoverTransitionDuration}
          slotProps={{
            paper: {
              ref: exp_useNativePopover
                ? (el) => {
                    el?.setAttribute('popover', 'auto');
                    popoverContentRef.current = el;
                  }
                : undefined,
              id: exp_useNativePopover ? popoverId : undefined,
              sx: mixinSx(
                keepButtonVisible
                  ? {
                      maxHeight: maxHeight
                        ? `calc(min(100vh - ${getMinYForPopover()}px, ${maxHeight}px))`
                        : `calc(100vh - ${getMinYForPopover()}px)`,
                    }
                  : undefined,
                exp_useNativePopover
                  ? {
                      padding: 0,
                      margin: 0,
                      inset: 'unset',
                      border: 'none',
                      position: 'fixed',
                    }
                  : undefined,
                paperSx
              ),
            },
          }}
          ref={exp_useNativePopover ? undefined : popoverContentRef}
        >
          {typeof children === 'function' ? children(onClosePopover) : children}
        </Popover>
      </>
    );
  })
);
