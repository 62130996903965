import { useCallback, useEffect, useMemo, useState } from 'react';
import { GQPageInfo } from '@watershed/shared-universal/generated/graphql-schema-types';

import { Trans, useLingui } from '@lingui/react/macro';
import { Box, SxProps, Typography, type Theme } from '@mui/material';
import Button, { ButtonProps } from '@watershed/ui-core/components/Button';
import ChevronLeftIcon from '@watershed/icons/components/ChevronLeft';
import ChevronRightIcon from '@watershed/icons/components/ChevronRight';
import {
  DataGridPremiumProps,
  UNKNOWN_ROW_COUNT,
} from '@watershed/ui-core/components/DataGrid/DataGrid';
import gql from 'graphql-tag';
import IconButton, {
  IconButtonProps,
} from '@watershed/ui-core/components/IconButton';

export interface PaginationState {
  first: number | null;
  last: number | null;
  after: string | null;
  before: string | null;
}

const DEFAULT_PAGE_SIZE = 10;

gql`
  fragment PageInfo on PageInfo {
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
  }
  fragment PageInfoWithCount on PageInfo {
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
    totalRowCount
  }
`;

export type PageInfo = Omit<
  GQPageInfo,
  'totalRowCount' | 'endCursor' | 'startCursor'
> &
  // Optional `totalRowCount` field; it's opt-in so only used in certain cases.
  // Don't require everywhere to query it. Make the page info objects optional
  // as well, so that the component can be used without GraphQL.
  Partial<Pick<GQPageInfo, 'totalRowCount' | 'endCursor' | 'startCursor'>>;

export function PaginationController(props: {
  pageInfo: PageInfo;
  onPreviousPage: (pageInfo: PageInfo) => void;
  onNextPage: (pageInfo: PageInfo) => void;
  iconsOnly?: boolean;
  currentPage?: number;
  totalPages?: number;
  sx?: SxProps<Theme>;
}) {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="end"
      padding={2}
      gap={1}
      sx={props.sx}
    >
      <ButtonOrIconButton
        onClick={() => props.onPreviousPage(props.pageInfo)}
        disabled={props.pageInfo ? !props.pageInfo.hasPreviousPage : true}
        startIcon={<ChevronLeftIcon />}
        iconsOnly={props.iconsOnly}
      >
        {props.iconsOnly ? <ChevronLeftIcon /> : 'Previous'}
      </ButtonOrIconButton>
      <CurrentPageNumber
        currentPage={props.currentPage}
        totalPages={props.totalPages}
      />
      <ButtonOrIconButton
        onClick={() => props.onNextPage(props.pageInfo)}
        disabled={props.pageInfo ? !props.pageInfo.hasNextPage : true}
        endIcon={<ChevronRightIcon />}
        iconsOnly={props.iconsOnly}
      >
        {props.iconsOnly ? <ChevronRightIcon /> : 'Next'}
      </ButtonOrIconButton>
    </Box>
  );
}

function ButtonOrIconButton(
  props:
    | (ButtonProps & { iconsOnly: undefined | false })
    | (IconButtonProps & { iconsOnly: true })
) {
  if (props.iconsOnly === true) {
    const { iconsOnly: _, ...rest } = props;
    return <IconButton {...rest} />;
  }
  const { iconsOnly: _, ...rest } = props;
  return <Button {...rest} />;
}

function CurrentPageNumber(props: {
  currentPage?: number;
  totalPages?: number;
}) {
  const { t } = useLingui();
  const { currentPage, totalPages } = props;
  if (!currentPage) {
    return null;
  }
  const paginationStatus = t({
    message: `${currentPage} / ${totalPages}`,
    context: 'Pagination status',
  });
  if (!totalPages) {
    return (
      <Typography variant="body2">
        <Trans context="Displays page number on a pagination control">
          Page {currentPage}
        </Trans>
      </Typography>
    );
  }
  return <Typography variant="body2">{paginationStatus}</Typography>;
}

/**
 * Convenience hook that returns a PaginationState and functions that can be
 * used to mutate it.
 */
export default function usePagination(pageSize: number = DEFAULT_PAGE_SIZE): {
  paginationState: PaginationState;
  fetchPreviousPage: (pageInfo: PageInfo) => void;
  fetchNextPage: (pageInfo: PageInfo) => void;
  resetPaginationState: () => void;
} {
  const [paginationState, setPaginationState] = useState<PaginationState>({
    first: pageSize,
    last: null,
    after: null,
    before: null,
  });

  const respObject = useMemo(() => {
    function fetchNextPage(pageInfo: PageInfo) {
      setPaginationState({
        ...paginationState,
        after: pageInfo.endCursor ?? null,
        before: null,
        first: pageSize,
        last: null,
      });
    }

    function fetchPreviousPage(pageInfo: PageInfo) {
      setPaginationState({
        ...paginationState,
        after: null,
        before: pageInfo.startCursor ?? null,
        first: null,
        last: pageSize,
      });
    }

    function resetPaginationState() {
      setPaginationState({
        first: pageSize,
        last: null,
        after: null,
        before: null,
      });
    }

    return {
      paginationState,
      fetchPreviousPage,
      fetchNextPage,
      resetPaginationState,
    };
  }, [paginationState, pageSize]);

  return respObject;
}

export type DataGridPagination = ReturnType<typeof useDataGridPagination>;

/**
 * Subset of DataGrid props used for pagination.
 */
export interface DataGridPaginationProps
  extends Pick<
    DataGridPremiumProps,
    | 'paginationMode'
    | 'paginationModel'
    | 'onPaginationModelChange'
    | 'pageSizeOptions'
    | 'rowCount'
    | 'pagination'
  > {
  pagination: true;
  paginationMode: 'server'; //override to specifically server but still pick from the props above to ensure our override is assignable
}

/**
 * If you are applying server-side pagination on a DataGrid,
 * this is the hook to use.
 *
 * With server-side pagination, it's often necessary to add `totalRowCount`
 * to the `pageInfo` object returned by the GraphQL query, and use
 * `gqpaginate`'s `includeTotalRowCount` option to enable proper counts for
 * the total number of rows.
 *
 * Then you can use a custom Toolbar component to render the form
 * fields. (See DataGridToolbar for more details on that part!).
 *
 * Here is an example of the whole things working together:
 *
 * export default function MyPage() {
 *   const { paginationState, getGridPaginationProps } = useDataGridPagination();
 *   const [result] = useMyPageQuery({
 *      variables: {
 *        ...paginationState,
 *      },
 *    });
 *
 *    const data = getGqlResultData(result);
 *    const rows = flattenConnection(data?.myRows);
 *
 *    return (
 *      <PageContainer>
 *        <DataGrid<GQMyRowFragment, GridFilters>
 *          rows={rows}
 *          columns={columns}
 *          slots={{ toolbar: Toolbar }}
 *          loading={isFetchingOrStale(result)}
 *          {...getGridPaginationProps}
 *        />
 *      </PageContainer>
 *    );
 * }
 *
 */
export function useDataGridPagination({
  pageSize: initialPageSize = DEFAULT_PAGE_SIZE,
  pageSizeOptions = [10, 25, 100],
}: {
  pageSize?: number;
  pageSizeOptions?: Array<number>;
} = {}) {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(initialPageSize);

  const {
    paginationState,
    fetchPreviousPage,
    fetchNextPage,
    resetPaginationState,
  } = usePagination(pageSize);

  useEffect(() => {
    if ((paginationState.first ?? paginationState.last) !== pageSize) {
      resetPaginationState();
    }
  }, [paginationState, pageSize, resetPaginationState]);

  const resetPaginationStateAndPage = useCallback(() => {
    setPage(0);
    resetPaginationState();
  }, [resetPaginationState]);

  const handlePageChange = useCallback(
    (newPage: number, pageInfo?: PageInfo | null) => {
      if (!pageInfo) return;
      setPage(newPage);
      if (newPage <= page) {
        fetchPreviousPage(pageInfo);
      } else {
        fetchNextPage(pageInfo);
      }
    },
    [fetchNextPage, fetchPreviousPage, page]
  );

  const getGridPaginationProps = useCallback(
    (pageInfo?: PageInfo | null): DataGridPaginationProps => ({
      pagination: true,
      paginationMode: 'server' as const,
      onPaginationModelChange: (model) => {
        if (model.page !== page) {
          handlePageChange(model.page, pageInfo);
        }
        if (model.pageSize !== pageSize) {
          setPageSize(model.pageSize);
          // We need to reset the pagination cursors when the page size changes;
          // otherwise, DataGrid will get confused about which page number we're
          // currently on.
          resetPaginationStateAndPage();
        }
      },
      pageSizeOptions,
      paginationModel: {
        pageSize,
        page,
      },
      rowCount:
        pageInfo?.totalRowCount ??
        (pageInfo?.hasNextPage ? UNKNOWN_ROW_COUNT : pageSize * (page + 1)),
    }),
    [
      handlePageChange,
      page,
      pageSize,
      pageSizeOptions,
      resetPaginationStateAndPage,
    ]
  );

  return {
    paginationState,
    getGridPaginationProps,
    fetchPreviousPage,
    fetchNextPage,
    resetPaginationState: resetPaginationStateAndPage,
  };
}
